import CoreValues from "../Components/about-us-page-components/CoreValues";
import KeyClients from "../Components/about-us-page-components/KeyClients";
import Machines from "../Components/about-us-page-components/Machines";
import MissionAndVision from "../Components/about-us-page-components/MissionAndVision";
import AboutPageDirec from "../Pages/about-page/AboutPageDirec";
import AboutPageReg from "../Pages/about-page/AboutPageReg";
import AboutPageTechnical from "../Pages/about-page/AboutPageTechnical";
import AboutUs from "../Pages/about-page/AboutUs";

const aboutusRoutes = [
  {
    path: "/about/history",
    element: <AboutUs />
  },
  {
    path: "/about/mission-and-vision",
    element: <MissionAndVision />
  },
  {
    path: "/about/directorates",
    element: <AboutPageDirec/>
  },
  {
    path: "/about/core-values",
    element: <CoreValues/>
  },
  {
    path: "/about/regulations&policies",
    element: <AboutPageReg/>
  },
  {
    path: "/about/machines",
    element: <Machines />
  },
  {
    path: "/about/clients",
    element: <KeyClients />
  },
];

export default aboutusRoutes;
