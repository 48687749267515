import React from "react";
import { useTranslation } from "react-i18next";
import { stringConcat } from "../../helper-functions/formatText";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const MuseumVisits = () => {
  const { t } = useTranslation();
  const cardData = [
    {
      image: "assets/images/Home/project-img1.jpg",
      title: t("ongoingProjects-1st-project-title"),
      desc: t("ongoingProjects-1st-project-desc"),
      bughet: t("ongoingProjects-1st-project-budget")
    },
    {
      image: "assets/images/Home/project-img2.jpg",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet: t("ongoingProjects-2nd-project-budget")
    },
    {
      image: "assets/images/Home/project-img3.jpg",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet: t("ongoingProjects-3rd-project-budget")
    },
    {
      image: "assets/images/Home/project-img4.jpg",
      title: t("ongoingProjects-4th-project-title"),
      desc: t("ongoingProjects-4th-project-desc"),
      bughet: t("ongoingProjects-4th-project-budget")
    },
    {
      image: "assets/images/Home/project-img5.jpg",
      title: t("ongoingProjects-5th-project-title"),
      desc: t("ongoingProjects-5th-project-desc"),
      bughet: t("ongoingProjects-5th-project-budget")
    },
    {
      image: "assets/images/Home/project-img6.jpg",
      title: t("ongoingProjects-6th-project-title"),
      desc: t("ongoingProjects-6th-project-desc"),
      bughet: t("ongoingProjects-6th-project-budget")
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="w-full md:w-[1150px] m-auto ">
        <div className="pb-20">
          <h1 className="pb-2 md:pl-0 pl-8 text-3xl md:text-4xl font-semibold text-center">
            {t("ongoingProjects-main-title")}
          </h1>
          <div className="bg-usetheme w-[10%] h-1 mx-auto mt-1 mb-5"></div>


       
           <Slider {...sliderSettings}>
           {cardData.map((item, index) => (
              <div
                key={index}
                className="group shadow-2xl cursor-pointer p-2  flex flex-col"
              >
                <div className="flex-grow overflow-hidden">
                  <img
                    src={item.image}
                    alt=""
                    className="w-full h-96 rounded-[70px] p-4 border-2 border-mstheme   object-cover "
                  />
                </div>
                <table class="table-auto border-collapse border border-gray-300 w-full text-left mt-4">
                  <thead class="bg-gray-100">
                    <tr>
                      <th class="border border-gray-300 bg-mstheme text-white px-4 py-2">Contract NO</th>
                      <th class="border border-gray-300 bg-mstheme text-white px-4 py-2">Agency</th>
                      <th class="border border-gray-300 bg-mstheme text-white px-4 py-2">Location</th>
                      <th class="border border-gray-300 bg-mstheme text-white px-4 py-2">Province</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border border-gray-300 px-4 py-2">{item.title}</td>
                      <td class="border border-gray-300 px-4 py-2">{item.desc.slice(0,20)}</td>
                      <td class="border border-gray-300 px-4 py-2">All Over Afghanistan</td>
                      <td class="border border-gray-300 px-4 py-2">All Over Afghanistan</td>
                    </tr>
                  </tbody>
                </table>
                <div className="flex justify-center items-center">
                <Link to="/agccProjects" className="bg-mstheme text-center w-36 rounded py-2 mt-3 mx-auto text-white font-semibold text-xl">{t("eventSection-btn")}</Link>

                </div>


                {/* <div className="px-5 py-3 flex-grow">
                  <h1 className="text-xl font-semibold hover:underline">
                    {item.title}
                  </h1>
                  <p className="pt-3">Agency : {stringConcat(item.desc, 45)}</p>
                </div> */}
              </div>
            ))}
           </Slider>
      
        </div>
        <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
      </div>
    </>
  );
};

export default MuseumVisits;
