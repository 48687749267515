import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


const EventsHeroCompo = ({events}) => {
  const { t } = useTranslation();
  const articlesData = [
    {
      image:
        "https://scontent.fpew3-1.fna.fbcdn.net/v/t39.30808-6/441157377_897935999044440_3846929826851875522_n.jpg?stp=c174.0.612.612a_cp6_dst-jpg_s206x206&_nc_cat=111&ccb=1-7&_nc_sid=714c7a&_nc_eui2=AeHE0HViZwYVNilY7l_Ga4vQJDSs8u-6Y5wkNKzy77pjnKKcT2AmHf4SDi-nSL2c1nv_UmOnmSsC8aNGpmy16Gc6&_nc_ohc=-wiuGFfG8n8Q7kNvgGKqMZR&_nc_ht=scontent.fpew3-1.fna&_nc_gid=AD7jItprp5M444VlhaPLIcN&oh=00_AYAWxTJDbWwNtKqtTKn5L-e95qAofLgn3XDDBOMmi8T4QA&oe=66F38CE6",
      title: t("ongoingProjects-1st-project-title"),
      desc: t("ongoingProjects-1st-project-desc"),
      bughet:t("ongoingProjects-1st-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1682088715035-11e11e28f50f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "https://images.unsplash.com/photo-1554907984-15263bfd63bd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-4th-project-title"),
      desc: t("ongoingProjects-4th-project-desc"),
      bughet:t("ongoingProjects-4th-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-5th-project-title"),
      desc: t("ongoingProjects-5th-project-desc"),
      bughet:t("ongoingProjects-5th-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-6th-project-title"),
      desc: t("ongoingProjects-6th-project-desc"),
      bughet:t("ongoingProjects-6th-project-budget")
    },
  ];
  
  // Number of articles to show initially
  const initialCount = 5;
  const [visibleArticles, setVisibleArticles] = useState(initialCount);

  // Handler for loading more articles
  const loadMoreArticles = () => {
    setVisibleArticles((prevVisible) => prevVisible + initialCount);
  };

  return (
    <section className="articles-section bg-gray-100">
      {/* Hero Section */}
      <div
        className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
        style={{
          backgroundImage:
            'url("https://cdn.pixabay.com/photo/2024/08/28/14/32/cityscape-9004205_1280.jpg")',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
          <h1 className="text-5xl font-bold mb-2">
            {t("articlesPage-main-title")}
          </h1>
          <div className="bg-usetheme w-[10%] h-1   mx-auto"></div>


          {/* <p className="text-xl mb-6">
           {t("articlesPage-main-desc")}
          </p> */}
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Featured Articles */}
        <div className="featured-articles mb-12">
          <h2 className="text-4xl font-bold text-center">
            {t("articlesPage-title")}
          </h2>
          <div className="bg-usetheme w-[10%] h-1 mt-1 mb-10 mx-auto"></div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
            {events.slice(0, visibleArticles).map((article) => (
              <div
                key={article.id}
                className="bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg transition-transform duration-300 transform hover:scale-105"
              >
                <Link to={`/article/${article.id}`}>
                  {/* Image Section */}
                  <div className="relative">
                    <img
                      className="w-full h-40 object-cover"
                      src={article.image}
                      alt={article.title}
                    />
                  </div>

                  {/* Content Section */}
                  <div className="p-3 flex flex-col justify-between h-40">
                    <h3 className="text-base font-semibold text-gray-800 truncate">
                      {article.title}
                    </h3>
                    <p className="text-gray-600 mt-1 text-sm line-clamp-2">
                      {article.description}
                    </p>
                    <div className="mt-3">
                      <button className="bg-mstheme text-white px-3 py-1.5 rounded-md text-sm hover:bg-mstheme transition-colors duration-200">
                        {t("articlesPage-card-btn")}
                      </button>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* Load More Button */}
          {visibleArticles < articlesData.length && (
            <div className="flex justify-center mt-10">
              <button
                onClick={loadMoreArticles}
                className="bg-mstheme text-white px-6 py-2 rounded-md hover:bg-mstheme transition-colors duration-200"
              >
                {t("articlesPage-load-more-btn")}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default EventsHeroCompo;
