import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const Introduction = () => {
    const {t} = useTranslation()
  return (
    <>
<div className='bg-gray-100 p-2'>
<h1 className='text-2xl bg-mstheme text-white  px-4 rounded-tr-xl rounded-br-sm rounded-bl-xl rounded-tl-sm w-64 mt-10 mx-80'>{t("footer.heading")}</h1>
     <div className='flex fle-col sm:flex-row justify-center items-center space-x-10 '>

        <div className='w-[50%] rounded mt-5 p-4'>
            <h1 className='text-mstheme text-xl font-semibold text-center'>{t("intro-title")}</h1>
            <p>{t("introduction")}</p>
            <div className='flex justify-center items-center'>
            <Link to='/about/history' className='bg-mstheme text-white   px-4 py-2 rounded hover:border-2 hover:border-mstheme hover:bg-white hover:text-mstheme'>{t("eventSection-btn")}</Link>
            </div>
        </div>

       <div className='w-[30%]'>
       <img src="https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_1280.jpg" 
        className='w-full rounded'
       alt="" />
       </div>

     </div>
</div>
    </>
  )
}

export default Introduction