import React from 'react'
import EventsHeroCompo from '../../Components/events-page-components/EventsHeroCompo'
import { useTranslation } from "react-i18next";

export default function EventsPage() {
  const { t } = useTranslation();
    const events = [
        {
          id: 1,
          title: t("eventsPage-up-com-events-1st-title"),
          description: t("eventsPage-up-com-events-1st-desc"),
          date: "2024-10-15", // upcoming event
          image:"/assets/images/Home/project-img1.jpg",
          
        },
        {
          id: 2,
          title: t("eventsPage-past-events-1st-title"),
          description: t("eventsPage-past-events-1st-desc"),
          date: "2024-08-10", // past event
          image: "/assets/images/Home/project-img2.jpg",
        },
        {
          id: 3,
          title: t("eventsPage-up-com-events-2nd-title"),
          description: t("eventsPage-up-com-events-2nd-desc"),
          date: "2024-12-20", // upcoming event
          image: "/assets/images/Home/project-img3.jpg",
          
        },
        {
          id: 4,
          title: t("eventsPage-past-events-2nd-title"),
          description: t("eventsPage-past-events-2nd-desc"),
          date: "2024-06-25", // past event
          image: "https://media.istockphoto.com/id/1399195000/photo/mother-and-daughter-in-art-gallery.webp?a=1&b=1&s=612x612&w=0&k=20&c=iJHjsZgKKxxecSe_fyjOdw6ML3DZB2FEZHUStIXLpBk=",
        },
        {
            id: 5,
            title: t("eventsPage-past-events-3rd-title"),
            description: t("eventsPage-past-events-3rd-title"),
            date: "2024-08-10", // past event
            image: "https://cdn.pixabay.com/photo/2019/11/12/23/00/artist-4622221_1280.jpg",
          },
          {
            id: 6,
            title: t("eventsPage-up-com-events-3rd-title"),
            description: t("eventsPage-up-com-events-3rd-desc"),
            date: "2024-12-20", // upcoming event
            image:       "/assets/images/Home/project-img3.jpg",

          },
          {
            id: 7,
            title: t("eventsPage-up-com-events-4th-title"),
            description: t("eventsPage-up-com-events-4th-desc"),
            date: "2024-12-20", // upcoming event
            image:       "/assets/images/Home/project-img4.jpg",

          },
      ];
  return (
    <>
    <EventsHeroCompo events={events} />
    </>
  )
}
