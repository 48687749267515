import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TbBrandYoutube } from "react-icons/tb";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";


const ArticlesHeroSection = () => {
  const { t } = useTranslation();
  const articlesData = [
    {
      image:
        "https://plus.unsplash.com/premium_photo-1682088715035-11e11e28f50f?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("      Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam quia id commodi error atque illo, deleniti deserunt iure non. Voluptate consectetur aliquid quo omnis ipsum accusantium minus libero ab. Obcaecati quasi, quia excepturi corrupti expedita facilis quisquam illo, ullam illum veniam facere nihil repellendus odit cupiditate quo voluptates reprehenderit magni."),
      bughet: t("ongoingProjects-2nd-project-budget")
    },
    
    {
      image:
        "https://images.unsplash.com/photo-1554907984-15263bfd63bd?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet: t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-4th-project-title"),
      desc: t("ongoingProjects-4th-project-desc"),
      bughet: t("ongoingProjects-4th-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-5th-project-title"),
      desc: t("ongoingProjects-5th-project-desc"),
      bughet: t("ongoingProjects-5th-project-budget")
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1683140790296-6815a1675b75?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: t("ongoingProjects-6th-project-title"),
      desc: t("ongoingProjects-6th-project-desc"),
      bughet: t("ongoingProjects-6th-project-budget")
    },
  ];

  // Number of articles to show initially
  const initialCount = 4;
  const [visibleArticles, setVisibleArticles] = useState(initialCount);

  // Handler for loading more articles
  const loadMoreArticles = () => {
    setVisibleArticles((prevVisible) => prevVisible + initialCount);
  };

  return (
    <section className="articles-section bg-gray-100">
      {/* Hero Section */}
      <div
        className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
        style={{
          backgroundImage:
            'url("https://cdn.pixabay.com/photo/2021/06/25/12/26/social-media-6363633_640.jpg")',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
          <h1 className="text-5xl font-bold mb-4">
            {t("articlesPage-main-title")}
          </h1>
          <div className="bg-usetheme w-[10%] h-1   mx-auto"></div>

          {/* <p className="text-xl mb-6">
           {t("articlesPage-main-desc")}
          </p> */}
        </div>
      </div>

      <div className="container mx-auto px-4 py-16">
        {/* Featured Articles */}
        <div className="featured-articles mb-12">
          {/* <h2 className="text-4xl font-bold text-center mb-10">
            {t("articlesPage-title")}
          </h2> */}
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6">
            {articlesData.slice(0, visibleArticles).map((article) => (
              <div
                key={article.id}
                className="  shadow-md rounded-lg overflow-hidden "
              >
                <Link to={`/article/${article.id}`} className="flex flex-row justify-center ">
                  {/* Image Section */}
                  <div className="w-[30%] relative bg-white hover:shadow-lg transition-transform duration-300 transform hover:scale-105">
                    <img
                      className="w-full h-96 object-cover"
                      src={article.image}
                      alt={article.title}
                    />
                  </div>

                  {/* Content Section */}
                  <div className="w-[30%] p-10 flex flex-col   bg-white">

                    <h3 className="text-base font-semibold text-gray-800 truncate">
                      {article.title}
                    </h3>
                    <p className="text-gray-600 mt-1 text-sm line-clamp-3">
                      {article.desc}
                    </p>
                
                    <div className="mt-3">
                      <button className="bg-mstheme text-white px-3 py-1.5 rounded-md text-sm hover:bg-mstheme transition-colors duration-200">
                        {t("articlesPage-card-btn")}
                      </button>
                    </div>
                    <p className=" mt-5 text-sm line-clamp-2 flex flex-col justify-end h-48">
                
                      <p className="text-mstheme text-sm flex">
                    <a
                        href="https://www.facebook.com/nationalmuseum.af"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl mx-3   rounded"
                      >
                        <FiFacebook className="hover:scale-110  transition-transform duration-300" />
                      </a>
                      <a
                        href="https://www.facebook.com/nationalmuseum.af"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl mx-3   rounded"
                      >
                        <TbBrandYoutube className="hover:scale-110  transition-transform duration-300" />
                      </a>
                 
                      <a
                        href="https://twitter.com/nationalmuseum.afg"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl mx-3   rounded"
                      >
                        <FiTwitter className="hover:scale-110  transition-transform duration-300" />
                      </a>
                      <a
                        href="https://www.instagram.com/nationalmuseum.afg/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl mx-3   rounded"
                      >
                        <FiInstagram className="hover:scale-110  transition-transform duration-300" />
                      </a>
                      <a
                        href="https://linkedin.com/nationalmuseum.afg"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-xl mx-3   rounded"
                      >
                        <FiLinkedin className="hover:scale-110  transition-transform duration-300" />
                      </a>
                    </p>
                    </p>
                 
                  </div>
                </Link>
              </div>
            ))}
          </div>

          {/* Load More Button */}
          {visibleArticles < articlesData.length && (
            <div className="flex justify-center mt-10">
              <button
                onClick={loadMoreArticles}
                className="bg-mstheme text-white px-6 py-2 rounded-md hover:bg-mstheme transition-colors duration-200"
              >
                {t("articlesPage-load-more-btn")}
              </button>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ArticlesHeroSection;
