import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination";

const PhotosGallery = () => {
  const { t } = useTranslation();

  // Gallery images
  const images = [
    {
      id: 2,
      title: t("eventSection.1st-image-title"),
      description:t("eventSection.1st-image-desc"),
      src:
        "/assets/images/Home/project-img1.jpg",
    },
    {
      id: 3,
      title: t("eventSection.2nd-image-title"),
      description:t("eventSection.2nd-image-desc"),
      src:
      "/assets/images/Home/project-img2.jpg",
    },
    {
      id: 4,
      title: t("eventSection.3rd-image-title"),
      description:t("eventSection.3rd-image-desc"),
      src:
      "/assets/images/Home/project-img3.jpg",
    },
    {
      id: 5,
      title:t("eventSection.4th-image-title"),
      description:t("eventSection.4th-image-desc"),
      src:
      "/assets/images/Home/project-img4.jpg",
    },
    {
      id: 6,
      title:t("eventSection.5th-image-title"),
      description:t("eventSection.5th-image-desc"),
      src:
      "/assets/images/Home/project-img5.JPG",
    },
    {
      id: 7,
      title: t("eventSection.6th-image-title"),
      description:t("eventSection.6th-image-desc"),
      src:
      "/assets/images/Home/project-img6.JPG",
    },
    {
      id: 8,
      title: t("eventSection.7th-image-title"),
      description:t("eventSection.7th-image-desc"),
      src:
      "/assets/images/Home/project-img7.JPG",
    },
    {
      id: 9,
      title: t("eventSection.8th-image-title"),
      description:t("eventSection.8th-image-desc"),
      src:
        "/assets/images/Home/project-img8.jpg",
    },
    {
      id: 10,
      title: t("eventSection.9th-image-title"),
      description:t("eventSection.9th-image-desc"),
      src:
        "/assets/images/Home/project-img9.jpg",
    },
  ];

  const [selectedImage, setSelectedImage] = useState(images[0].src);

  return (
    <>
      <div className="min-h-screen w-full">
        {/* Background Section */}
        <div
          className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
          style={{
            backgroundImage: `url("https://images.unsplash.com/photo-1505027014503-e6de34d28116?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
            <h1 className="text-5xl font-bold mb-4">
              {t("galleryPhotos-main-title")}
              <div className="bg-usetheme w-[20%] mt-2 h-1   mx-auto"></div>

            </h1>
            {/* <p className="text-xl mb-6">{t("galleryPhotos-main-desc")}</p> */}
          </div>
        </div>

        {/* Header Section */}
        <div className="w-full px-4 md:px-0 md:w-[1100px] mx-auto my-24">
          <h1 className="text-center text-4xl font-bold my-8 text-mstheme">
            {t("galleryPhotos-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
          <p className="text-xl my-8">{t("galleryPhotos-desc")}</p>
        </div>

        {/* Gallery Section */}
        <div className="w-full px-4 md:px-0 md:w-[1100px] mx-auto my-24">
          <h1 className="text-center text-4xl font-bold my-8 text-mstheme">
            {t("galleryPhotos-gallery-view-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>

          <div className="flex flex-col items-center gap-8 my-16">
            {/* Main Image */}
            <div className="w-full md:w-3/4">
              <img
                className="w-full h-auto rounded-lg shadow-lg"
                src={selectedImage}
                alt="Selected"
              />
            </div>

            {/* Smaller Images */}
            <div className="flex gap-4 overflow-x-auto scrollbar-hide">
              {images.map((image, index) => (
                <div
                  key={index}
                  className="cursor-pointer hover:opacity-75 transition duration-300"
                  onClick={() => setSelectedImage(image.src)}
                >
            
                  <img
                    className="w-32 h-32 md:w-32 md:h-28 object-cover rounded-lg shadow-md"
                    src={image.src}
                    alt={image.alt}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    <div className="my-10">
    <Pagination
        totalItems={3} // Total number of items
        itemsPerPage={3} // Number of items per page
        // currentPage={currentPage} // Current active page
        // onPageChange={handlePageChange} // Callback to handle page changes
      />
    </div>
    </>
  );
};

export default PhotosGallery;
