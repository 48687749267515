import React from 'react'
import { useTranslation } from "react-i18next";
import ExhibitionHeroCompo from '../../Components/exhibition-page-components/ExhibitionHeroCompo';

export default function ExhibitionPage() {
  const { t } = useTranslation();
  
  const events = [
    {
      id: 2,
      title: t("eventSection.1st-image-title"),
      description:t("eventSection.1st-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2018/11/13/17/52/consulting-3813576_640.jpg"
    },
    {
      id: 3,
      title: t("eventSection.2nd-image-title"),
      description:t("eventSection.2nd-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2019/02/06/16/32/architect-3979490_1280.jpg",
    },
    {
      id: 4,
      title: t("eventSection.3rd-image-title"),
      description:t("eventSection.3rd-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2016/11/24/20/30/floor-plan-1857175_640.jpg",
    },
    {
      id: 5,
      title:t("eventSection.4th-image-title"),
      description:t("eventSection.4th-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2017/08/03/21/37/construction-2578410_640.jpg",
    },
    {
      id: 6,
      title:t("eventSection.5th-image-title"),
      description:t("eventSection.5th-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2020/08/28/06/13/building-5523630_640.jpg",
    },
    {
      id: 7,
      title: t("eventSection.6th-image-title"),
      description:t("eventSection.6th-image-desc"),
      image:
      "https://cdn.pixabay.com/photo/2019/12/10/20/59/site-4686908_640.jpg",
    },
    // {
    //   id: 8,
    //   title: t("eventSection.7th-image-title"),
    //   description:t("eventSection.7th-image-desc"),
    //   image:
    //   "https://cdn.pixabay.com/photo/2016/02/01/21/15/excavator-1174428_640.jpg",
    // },
    // {
    //   id: 9,
    //   title: t("eventSection.8th-image-title"),
    //   description:t("eventSection.8th-image-desc"),
    //   image:
    //     "https://cdn.pixabay.com/photo/2012/11/28/11/06/girl-67694_1280.jpg",
    // },
    // {
    //   id: 10,
    //   title: t("eventSection.9th-image-title"),
    //   description:t("eventSection.9th-image-desc"),
    //   image:
    //     "https://cdn.pixabay.com/photo/2017/08/28/18/44/international-2690812_1280.jpg",
    // },
  ];

  return (
    <>
    <ExhibitionHeroCompo events={events} />
    </>
  )
}
