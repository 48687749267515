import React from 'react';
import { useTranslation } from 'react-i18next';

const CoreValues = () => {
    const { t } = useTranslation();

    return (
        <div className="py-12 bg-gray-50">
            <div
                className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${"https://cdn.pixabay.com/photo/2012/07/06/20/23/excavator-51665_640.jpg"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-5xl font-bold mb-4">
                        {t("coreValues.title")}
                    </h1>
                    <p className="text-xl mb-6">
                        {/* {t("aboutHistory-main-desc")} */}
                    </p>
                </div>
            </div>
            <div className="container mx-auto px-6 mt-20">
                {/* <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">{t("coreValues.title")}</h1> */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {/* Teamwork Section */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("coreValues.teamwork.title")}</h2>
                        <p className="text-gray-600 mb-4">{t("coreValues.teamwork.desc1")}</p>
                        <p className="text-gray-600">{t("coreValues.teamwork.desc2")}</p>
                    </div>
                    {/* Integrity Section */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("coreValues.integrity.title")}</h2>
                        <p className="text-gray-600 mb-4">{t("coreValues.integrity.desc1")}</p>
                        <p className="text-gray-600">{t("coreValues.integrity.desc2")}</p>
                    </div>
                    {/* Commitment Section */}
                    <div className="bg-white shadow-md rounded-lg p-6">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("coreValues.commitment.title")}</h2>
                        <p className="text-gray-600 mb-4">{t("coreValues.commitment.desc1")}</p>
                        <p className="text-gray-600">{t("coreValues.commitment.desc2")}</p>
                    </div>
                </div>
                <div className="mt-10 text-center">
                    <h3 className="text-xl font-medium text-gray-700">{t("coreValues.motto")}</h3>
                    <p className="text-gray-600 mt-2">{t("coreValues.mottoDesc")}</p>
                </div>
            </div>
        </div>
    );
};

export default CoreValues;
