import React from "react";
import { useTranslation } from "react-i18next";

const AboutHistory = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="min-h-screen w-full">
        {/* start of Background Image */}
        <div
          className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${"https://cdn.pixabay.com/photo/2012/07/06/20/23/excavator-51665_640.jpg"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
            <h1 className="text-5xl font-bold mb-4">
              {t("aboutHistory-main-title")}
            </h1>
            <p className="text-xl mb-6">
              {/* {t("aboutHistory-main-desc")} */}
            </p>
          </div>
        </div>
        {/* ends of Background Image */}
        {/* heading with description start here */}
        {/* <div className="w-full px-4 md:px-0 md:w-[1100px] mx-auto my-24">
          <h1 className="text-center text-4xl font-bold my-8 text-mstheme">
          {t("titleWithDesc-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
          <p className="text-xl my-8">
            {t("titleWithDesc-desc")}
          </p>
        </div> */}
        {/* heading with description ends here */}
        {/* Historical topics starts here */}
        {/* <div className="w-full px-4 md:px-0 md:w-[1100px] mx-auto my-24">
          <h1 className="text-center text-4xl font-bold my-8 text-mstheme">
            {t("historicalTopics-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 my-16">
            {[
              {
                img: "https://images.pexels.com/photos/4162723/pexels-photo-4162723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                title: t("historicalTopics-1st-img-title"),
              },
              {
                img: "https://images.pexels.com/photos/6942814/pexels-photo-6942814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                title: t("historicalTopics-2nd-img-title"),
              },
              {
                img: "https://images.pexels.com/photos/4162723/pexels-photo-4162723.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                title: t("historicalTopics-3rd-img-title"),
              },
              {
                img: "https://images.pexels.com/photos/5327973/pexels-photo-5327973.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                title: t("historicalTopics-4th-img-title"),
              },
            ].map((item, index) => (
              <div
                key={index}
                className="relative bg-white rounded-lg overflow-hidden shadow-lg transform transition-all hover:scale-105 duration-300"
              >
                <img
                  className="w-full h-64 object-cover transition-transform duration-500 transform hover:scale-105"
                  src={item.img}
                  alt={item.title}
                />

                <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent opacity-80 p-4 flex items-end">
                  <h1 className="text-white text-lg font-bold">{item.title}</h1>
                </div>
              </div>
            ))}
          </div>

        </div> */}
        {/* Historical topics ends here */}
        {/* gallery collection starts here */}
        {/* <div className="w-full px-4 md:px-0 md:w-[1100px] mx-auto my-24">
          <h1 className="text-center text-4xl font-bold my-8 text-mstheme">
            {t("historicalTime-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
          <div className="flex flex-col md:flex-row justify-center gap-12 my-16">
            <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ease-in-out w-[320px]">
              <img
                className="w-full h-[250px] object-cover"
                src="https://cdn.pixabay.com/photo/2012/10/10/15/46/girl-60636_1280.jpg"
              />
              <div className="p-4">
                <h1 className="text-lg font-semibold mb-2">{t("historicalTime-1st-img-title")}</h1>
                <p className="text-sm text-gray-600">
                  {t("historicalTime-1st-img-desc")}
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ease-in-out w-[320px]">
              <img
                className="w-full h-[250px] object-cover"
                src="https://cdn.pixabay.com/photo/2012/10/10/17/53/men-60744_960_720.jpg"
                alt="Afghanistan National Flag"
              />
              <div className="p-4">
                <h1 className="text-lg font-semibold mb-2">{t("historicalTime-2nd-img-title")}</h1>
                <p className="text-sm text-gray-600">
                  {t("historicalTime-2nd-img-desc")}
                </p>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ease-in-out w-[320px]">
              <img
                className="w-full h-[250px] object-cover"
                src="https://cdn.pixabay.com/photo/2013/02/20/00/52/afghanistan-83496_1280.jpg"
                alt="Intricately carved stone deity"
              />
              <div className="p-4">
                <h1 className="text-lg font-semibold mb-2">{t("historicalTime-3rd-img-title")}</h1>
                <p className="text-sm text-gray-600">
                  {t("historicalTime-3rd-img-desc")}
                </p>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ease-in-out w-[320px]">
              <img
                className="w-full h-[250px] object-cover"
                src="https://media.istockphoto.com/id/1256983065/photo/jewellery-of-afganistan.jpg?s=1024x1024&w=is&k=20&c=lPp75xQXluwSo6VIPOUwLkRKzeNCVb1cfKvp8UyWF80="
                alt="Intricately carved stone deity"
              />
              <div className="p-4">
                <h1 className="text-lg font-semibold mb-2">{t("historicalTime-4th-img-title")}</h1>
                <p className="text-sm text-gray-600">
                  {t("historicalTime-4th-img-desc")}
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* gallery collection ends here */}
        <section className="bg-white py-16">
          <div className="container mx-auto px-6 lg:px-20">
            <div className="text-center mb-12">
              <h2 className="text-4xl font-bold text-usetheme leading-tight mb-4">
                About Areyana Group Construction Company
              </h2>
              <p className="text-lg text-gray-600">
                Building Excellence Since 2002
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <h3 className="text-2xl font-semibold text-usetheme">
                  Our Journey
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  Areyana Group Construction Company (AGCC) was established in 2002 in the southern region of Afghanistan, with its main office in Khost. After successfully completing numerous projects across the country, AGCC expanded to Kabul and beyond, solidifying its reputation for excellence in construction and management services.
                </p>

                <h3 className="text-2xl font-semibold text-usetheme">
                  Commitment to Excellence
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  AGCC is proud to be CCR registered and the holder of the prestigious ASIA certificate, demonstrating its compliance with U.S. government requirements. With a DUNS number 850459235, AGCC is recognized by the Department of Defense and the U.S. Army.
                </p>
              </div>

              <div className="space-y-6">
                <h3 className="text-2xl font-semibold text-usetheme">
                  Our Mission
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  AGCC aims to actively contribute to Afghanistan's construction and rehabilitation, creating job opportunities for vulnerable populations through diverse projects. The company is committed to delivering quality services and adhering to the highest standards in the industry.
                </p>

                <h3 className="text-2xl font-semibold text-usetheme">
                  National Presence
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  With offices in Kabul, Nangarhar, Kunar, and the northern regions, AGCC operates nationwide, providing program management, project management, and design/build services across various engineering disciplines.
                </p>
              </div>
            </div>

            <div className="mt-12 text-center">
              <p className="text-lg text-gray-600">
                "Our reputation for excellence is built on trust, quality, and a commitment to exceeding client expectations."
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutHistory;
