import React from 'react';
import { useTranslation } from 'react-i18next';

const MissionAndVision = () => {
    const { t } = useTranslation();

    return (
        <div className="py-10 ">
            <div
                className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
                style={{
                    backgroundImage: `url(${"https://cdn.pixabay.com/photo/2012/07/06/20/23/excavator-51665_640.jpg"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}
            >
                <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
                    <h1 className="text-5xl font-bold mb-4">
                        {t("missionVision.title")}
                    </h1>
                    <p className="text-xl mb-6">
                        {/* {t("aboutHistory-main-desc")} */}
                    </p>
                </div>
            </div>
            <div className="container mx-auto px-4 mt-20">
                {/* <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">{t("missionVision.title")}</h1> */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                    {/* Competitive Environment */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("missionVision.competitiveEnvironment.title")}</h2>
                        <p className="text-gray-600">{t("missionVision.competitiveEnvironment.desc")}</p>
                    </div>
                    {/* Work Environment */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("missionVision.workEnvironment.title")}</h2>
                        <p className="text-gray-600">{t("missionVision.workEnvironment.desc")}</p>
                    </div>
                    {/* Company Culture */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("missionVision.companyCulture.title")}</h2>
                        <p className="text-gray-600">{t("missionVision.companyCulture.desc")}</p>
                    </div>
                    {/* Company Success */}
                    <div className="bg-white shadow-md p-6 rounded-lg">
                        <h2 className="text-2xl font-semibold text-mstheme mb-4">{t("missionVision.companySuccess.title")}</h2>
                        <p className="text-gray-600">{t("missionVision.companySuccess.desc")}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MissionAndVision;
