import React from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AboutMuseum = () => {
  const { t } = useTranslation();

  // Dummy data for partners
  const partners = [
    { id: 1, name: t('articlesPage-1st-card-title').slice(27), img: "https://cdn.pixabay.com/photo/2019/03/15/00/11/brussels-4056171_1280.jpg" , to :"https://www.army.mil/" },
    { id: 4, name: t('articlesPage-4th-card-title'), img: 'https://cdn.pixabay.com/photo/2020/03/30/16/40/un-4984799_1280.jpg'  , to : 'https://www.nato.int/cps/en/natohq/topics_69366.htm'},
    { id: 3, name: t('articlesPage-3rd-card-title'), img: 'https://cdn.pixabay.com/photo/2017/08/21/20/33/army-engineers-2666794_640.jpg' , to:"https://www.usace.army.mil/" },
    { id: 5, name: t('articlesPage-5th-card-title'), img: 'https://cdn.pixabay.com/photo/2019/03/15/00/11/brussels-4056171_1280.jpg', to:"https://www.un.org/en/" },
    { id: 2, name: t('articlesPage-2nd-card-title'), img: 'https://cdn.pixabay.com/photo/2017/07/21/18/42/us-army-2526756_640.jpg' , to :"https://www.army.mil/" },
    { id: 2, name: t('articlesPage-2nd-card-title'), img: 'https://cdn.pixabay.com/photo/2017/07/21/18/42/us-army-2526756_640.jpg' , to :"https://www.army.mil/" },

  ];
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
 
        
      <div className="w-full md:w-[1450px] m-auto mb-16">
      <div className="py-10 ">
      <h1 className="text-3xl md:text-4xl font-semibold text-center mt-9">
        {t('keyPartners.title')}
      </h1>
      <div className="bg-usetheme w-[10%] h-1  mt-2 mb-10 mx-auto"></div>
        {/* React Slick Slider */}
        <Slider {...sliderSettings}>
          {partners.map((item, index) => (
            <div key={index} className="p-2">
              <Link to={item.to} target='_blank' className="group shadow-2xl cursor-pointer w-[210px]  flex flex-col mx-auto mb-5">
                <div className="flex-grow overflow-hidden">
                  <img
                    src={`/assets/images/Home/p${index+1}.jpg`}
                    alt=""
                    className="w-full h-auto object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                  />
                </div>
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>

      {/* <div className="w-full md:w-[1450px] mx-auto flex justify-center ">
        <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-7 gap-2 py-10">
          {partners.map((partner , index) => (
            <Link
              key={partner.id}
              target="_blank"
              to={partner.to}
              className="bg-usetheme  shadow-xl shadow-gray-400 rounded-md overflow-hidden hover:scale-105 transition-transform duration-300"
            >
              <img
                src={`/assets/images/Home/p${index+1}.jpg`}
                alt={partner.name}
                className="w-full h-52 object-cover"
              />
            
            </Link>
          ))}
        </div>
      </div> */}
      {/* <div className='flex justify-center items-center'>
      <Link className='bg-mstheme px-4 py-2 text-white font-semibold text-xl rounded'>{t("eventSection-btn")}</Link>

      </div> */}
      <div className="w-full h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent my-10"></div>
    </>
  );
};

export default AboutMuseum;
