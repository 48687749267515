import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown, FaGlobe } from "react-icons/fa"; // Importing only the dropdown icon
import { FaPhoneAlt, FaEnvelope, FaFacebook, FaYoutube, FaTwitter } from "react-icons/fa";
import mlogo from "../images/logo/mlogo.png";
import { useTranslation } from "react-i18next";
import { TbBrandYoutube } from "react-icons/tb";
import i18next from "i18next";
import cookies from "js-cookie";
import { GiHamburgerMenu } from "react-icons/gi";
import { FiFacebook, FiInstagram, FiLinkedin, FiTwitter } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { WiDirectionRight } from "react-icons/wi";
import { IoMdHome } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { GrServices } from "react-icons/gr";
import { GrProjects } from "react-icons/gr";
import { GoFileMedia } from "react-icons/go";
import { TfiGallery } from "react-icons/tfi";
import { RiContactsBookLine } from "react-icons/ri";
import { TbArrowRoundaboutRight } from "react-icons/tb";
import { FaSearch } from "react-icons/fa";
const Navbar = () => {
  const [navbarShow, setNavbarShow] = useState(false)
  const languages = [

    {
      code: "per",
      name: "دری | ",
      dir: "rtl",
      country_code: "ir",
    },
    {
      code: "ps",
      name: "پښتو",
      dir: "rtl",
      country_code: "pk",
    },
    {
      code: "en",
      name: "| English",
      country_code: "gb",
    },
  ];

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [topOffset, setTopOffset] = useState(40);


  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    if (cookies.get("i18next") === "ps" || cookies.get("i18next") === "per") {
      document.body.className = "persian-font";
    }
    else {
      document.body.className = "english-font";
    }
  }, [currentLanguage, t]);

  const toggleDropdown = () => {
    console.log("hover")
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageChange = (code) => {
    i18next.changeLanguage(code);
    localStorage.setItem("AGCC", code)
    setDropdownOpen(false); // Close dropdown after selection
  };

  const handleScroll = () => {
    if (window.scrollY > 700) {
      setTopOffset(0)
    }
    else {
      if (window.innerWidth < 640) {
        setTopOffset(75)
      }
      else setTopOffset(40)

    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <div className="fixed top-0 left-0 right-0 z-40 h-[80px] bg-gray-200  ">
        <div className="mx-2 sm:mx-20 flex   sm:justify-between items-center ">
          <div className="flex flex-col sm:flex-row items-start sm:items-center text-[12px] sm:text-md mt-1 text-gray-700">

            <div className="flex items-center rtl:flex-row-reverse gap-1 sm:gap-2">
              <FaPhoneAlt className="flex-shrink-0 text-mstheme" />
              <span className="unicode-bidi[override] rtl:text-left text-[8px] sm:text-sm">{t("visitorInfo-phone")}</span>
            </div>
            <div className="flex items-center rtl:flex-row-reverse gap-1 mx-2 sm:gap-2 mt-1 sm:mt-0  text-[8px] sm:text-sm">
              <FaEnvelope className="flex-shrink-0 text-mstheme" />
              <span>areyanagorup786@gmail.com</span>
            </div>
            {/* <div className="flex items-center rtl:flex-row-reverse gap-1 mx-2 sm:gap-2 mt-1 sm:mt-0  text-[8px] sm:text-sm">
              <BiLogoGmail className="flex-shrink-0 " />
              <span>{t("visitorInfo-email")}</span>
            </div> */}
          </div>

          <div>
            <button className="bg-mstheme text-white px-4 rounded-tr-xl rounded-br-sm rounded-bl-xl rounded-tl-sm">Get A Quote</button>
          </div>


          <div className="flex  text-gray-700">
            <div className=" text-[8px] sm:text-sm sm:font-semibold sm:px-4 mt-2 "
              dangerouslySetInnerHTML={{ __html: t("top-header.follow") }}
            />
            <div className="flex p-2 ">
              <a
                href="https://www.facebook.com/nationalmuseum.af"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[10px] sm:text-xl mx-1 sm:mx-3  text-mstheme rounded"
              >
                <TbBrandYoutube className="hover:scale-110  transition-transform duration-300 " />
              </a>
              <a
                href="https://www.facebook.com/nationalmuseum.af"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[10px] sm:text-xl mx-1 sm:mx-3  text-mstheme  rounded"
              >
                <FiFacebook to='https://www.facebook.com/profile.php?id=100038284699429' className="hover:scale-110  transition-transform duration-300" />
              </a>
              <a
                href="https://twitter.com/nationalmuseum.afg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[10px] sm:text-xl mx-1 sm:mx-3  text-mstheme  rounded"
              >
                <BsTwitterX className="hover:scale-110  transition-transform duration-300" />
              </a>
              <a
                href="https://www.instagram.com/nationalmuseum.afg/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[10px] sm:text-xl mx-1 sm:mx-3  text-mstheme  rounded"
              >
                <FiInstagram className="hover:scale-110  transition-transform duration-300" />
              </a>
              <a
                href="https://linkedin.com/nationalmuseum.afg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[10px] sm:text-xl mx-1 sm:mx-3  text-mstheme  rounded"
              >
                <FiLinkedin to='https://www.linkedin.com/in/areyana-group-zabet-khan-60198b209?originalSubdomain=af' className="hover:scale-110  transition-transform duration-300" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ top: `${topOffset}px` }}
        className="fixed left-0 right-0 z-40 transition-all duration-1000"
      >

        <div className="navbar  bg-gray-200 text-mstheme flex items-center justify-between px-4 ">
          {/* Logo Section */}
          <div className="navbar-start flex items-center w-full">
            <Link
              to="/"
              className="flex items-center text-lg font-bold   rounded gap-1"
            >
              <img
                src={mlogo}
                alt="National Museum Afghanistan Logo"
                className="h-6 md:h-12 ml-0  md:ml-12 w-auto mr-2"
              />
              {/* <p className="text-sm lg:text-[18.1px] tracking-wide text-center font-medium lg:font-semibold">{t("header.main-heading")}</p> */}
            </Link>
          </div>
          <div className="flex">
            <input
              type="text"
              className="w-[600px] border-mstheme outline-none border-2 rounded px-4 py-2 "
              placeholder="Search on Areyana Construction Company"
            />
            <FaSearch className="text-2xl text-mstheme relative right-10 " />
          </div>



          {/* Language Dropdown Section */}
          <div className="navbar-end flex items-center gap-2 relative me-28">
            {/* Dropdown Container */}
            <div
              className="flex items-center gap-2 relative"
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <div >
                {/* Dropdown Trigger */}
                {/* <label
                  tabIndex={0}
                  className="text-[16px] cursor-pointer font-medium flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2 rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <FaGlobe className="mr-1" size={25} />
                </label> */}

                {/* Dropdown Content */}

                <div className="p-2 shadow-lg bg-mstheme text-fontcolor rounded-box flex">
                  {languages
                    .filter((language) => language.code !== localStorage.getItem("AGCC"))
                    .map((language) => (
                      <div key={language.code}>
                        <button
                          className="w-full text-left px-2 hover:bg-mstheme_hover hover:text-hover_color rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                          onClick={() => handleLanguageChange(language.code)}
                        >
                          {language.name} 
                        </button>
                      </div>
                    ))}
                </div>


              </div>
            </div>

            {/* Mobile Hamburger Menu */}
            <div
              className="flex sm:hidden transition-all duration-700"
              onClick={() => setNavbarShow(!navbarShow)}
            >
              {navbarShow ? <RxCross2 /> : <GiHamburgerMenu />}
            </div>
          </div>



        </div>
        <div className="bg-gray-50 pt-5 pb-2 flex items-center justify-center">
          {/* Menu Section */}
          <div className="navbar-center hidden lg:flex">
            <ul className="menu menu-horizontal space-x-5 ml-24">
              <li>

                <Link
                  to="/"
                  className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <IoMdHome />  {t("header.first-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <TbArrowRoundaboutRight /> {t("header.second-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme text-fontcolor rounded-box w-44 sm:w-64 mt-1 z-20"
                >
                  <li>
                    <Link to='/about/history' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />  {t("header.second-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/mission-and-vision' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />  {t("header.second-sub-menu-2")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/core-values' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />  {t("header.second-sub-menu-3")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/directorates' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />  {t("header.second-sub-menu-4")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/regulations&policies' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />  {t("header.second-sub-menu-5")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/machines' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />   {t("header.second-sub-menu-6")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/clients' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />   {t("header.second-sub-menu-7")}
                    </Link>
                  </li>
                  <li>
                    <Link to='/about/regulations&policies' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />   {t("header.second-sub-menu-8")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="dropdown dropdown-hover">
                <Link
                  to='agccServices'
                  className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <GrServices />  {t("header.third-menu")}
                </Link>

              </li>
              <li>
                <Link
                  to="agccProjects"
                  className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <GrProjects /> {t("header.fourth-menu")}
                </Link>
              </li>
              <li>
                <Link
                  to="/agccNews"
                  className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <GoFileMedia />    {t("header.fifth-menu")}
                </Link>
              </li>
              <li className="dropdown dropdown-hover">
                <label
                  tabIndex={0}
                  className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <TfiGallery className="m-0 p-0" />    {t("header.sixth-menu")} <FaChevronDown className="ml-1" />
                </label>
                <ul
                  tabIndex={0}
                  className="dropdown-content p-2 shadow bg-mstheme text-fontcolor rounded-box w-40 mt-1 z-20"
                >
                  <li>
                    <Link to='/gallery/photos' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />          {t("header.sixth-sub-menu-1")}
                    </Link>
                  </li>
                  <li>
                    <Link to='gallery/videos' className="hover:bg-white hover:text-mstheme px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                      <WiDirectionRight size={30} />   {t("header.sixth-sub-menu-2")}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/contact-us"
                  className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                >
                  <RiContactsBookLine />  {t("header.seventh-menu")}
                </Link>
              </li>
            </ul>
          </div>

          {/* mobile menu */}

          {
            navbarShow && <div className="navbar-center flex flex-col lg:hidden ">
              <ul className="absolute bg-mstheme top-14 menu  space-x-5 ml-24">
                <li>
                  <Link
                    to="/"
                    className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.first-menu")}
                  </Link>
                </li>
                <li className="dropdown dropdown-hover">
                  <label
                    tabIndex={0}
                    className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.second-menu")} <FaChevronDown className="ml-1" />
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                  >
                    <li>
                      <Link to='/about/history' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.second-sub-menu-1")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/about/directorates' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.second-sub-menu-2")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/about/technical-staff' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.second-sub-menu-3")}
                      </Link>
                    </li>
                    <li>
                      <Link to='/about/regulations&policies' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.second-sub-menu-4")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown dropdown-hover">
                  <Link
                    to='exhibitions'
                    className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.third-menu")}
                  </Link>

                </li>
                <li>
                  <Link
                    to="/events"
                    className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.fourth-menu")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/articles"
                    className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.fifth-menu")}
                  </Link>
                </li>
                <li className="dropdown dropdown-hover">
                  <label
                    tabIndex={0}
                    className="text-[16px] font-medium cursor-pointer flex items-center hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.sixth-menu")} <FaChevronDown className="ml-1" />
                  </label>
                  <ul
                    tabIndex={0}
                    className="dropdown-content p-2 shadow bg-mstheme rounded-box w-40 mt-1 z-20"
                  >
                    <li>
                      <Link to='/gallery/photos' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.sixth-sub-menu-1")}
                      </Link>
                    </li>
                    <li>
                      <Link to='gallery/videos' className="hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none">
                        {t("header.sixth-sub-menu-2")}
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className="text-[16px] font-medium hover:bg-mstheme_hover hover:text-hover_color px-2  rounded focus:bg-mstheme_hover focus:text-white focus:outline-none"
                  >
                    {t("header.seventh-menu")}
                  </Link>
                </li>
              </ul>
            </div>
          }
        </div>
      </div>


      <a href="https://wa.me/+93781111773" target="_blank" className="fixed right-5 bottom-20 md:bottom-28 z-50 rounded-full">
        <img src="https://upload.wikimedia.org/wikipedia/commons/5/5e/WhatsApp_icon.png" alt="Whatsapp icon" className=" w-11 md:w-16" />
      </a>


    </>
  );
};

export default Navbar;
