import React from 'react';
import { useTranslation } from 'react-i18next';

const AreasOfCapabilities = () => {
  const { t } = useTranslation();

  const areas = [
    {
      title: t('capabilities-transport-title'),
      description: t('capabilities-transport-desc'),
    },
    {
      title: t('capabilities-structures-title'),
      description: t('capabilities-structures-desc'),
    },
    {
      title: t('capabilities-health-title'),
      description: t('capabilities-health-desc'),
    },
    {
      title: t('capabilities-water-title'),
      description: t('capabilities-water-desc'),
    },
    {
      title: t('capabilities-education-title'),
      description: t('capabilities-education-desc'),
    },
    {
      title: t('capabilities-environment-title'),
      description: t('capabilities-environment-desc'),
    },
    {
      title: t('capabilities-architecture-title'),
      description: t('capabilities-architecture-desc'),
    },
    {
      title: t('capabilities-management-title'),
      description: t('capabilities-management-desc'),
    },
  ];

  return (
    <div className="max-w-full mx-10  px-6 py-10">
      <h2 className="text-3xl font-semibold text-center mb-4">{t('capabilities-title')}</h2>
      <div className="bg-usetheme w-[10%] h-1  mt-1 mx-auto mb-10"></div>


      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {areas.map((area, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:border-l-2 cursor-pointer hover:border-mstheme hover:rounded-none">
            <h3 className="text-xl font-semibold mb-4 text-mstheme">{area.title}</h3>
            <p className="text-gray-700">{area.description}</p>
          </div>
        ))}
      </div>
      <div className="w-full mt-20  h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent "></div>

    </div>
  );
};

export default AreasOfCapabilities;
