import React from "react";
import { useTranslation } from "react-i18next";
import { BiLogoGmail } from "react-icons/bi";
import { BsTwitterX } from "react-icons/bs";
import { FaPhoneAlt } from "react-icons/fa";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import { TbBrandYoutube } from "react-icons/tb";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const AboutDirectorates = () => {
  const { t } = useTranslation();
  const cardData = [
    
    {
      image:
        "/assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "/assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },

    {
      image:
        "/assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
  
    {
      image:
        "/assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    
    

 
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
 <>
    <div className="flex">
      <div className="min-h-screen w-full">
        {/* start of Background Image */}
        <div
          className="w-full min-h-96 mt-[64px] bg-cover bg-center relative"
          style={{
            backgroundImage: `url(${"https://images.pexels.com/photos/1484776/pexels-photo-1484776.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white text-center px-6">
            <h1 className="text-5xl font-bold mb-4">
              {t("aboutDirectorate-main-title")}
            </h1>
            <p className="text-xl mb-6">
              {t("aboutDirectorate-main-desc")}
            </p>
          </div>
        </div>
        {/* ends of Background Image */}
        {/* heading start here */}
        <div className="w-full px-4 md:px-0 md:w-[1200px] mx-auto my-10">
          <h1 className="text-4xl font-bold my-8 text-mstheme">
            {t("director-msg-title")}
          </h1>
          <div className="w-full h-1 bg-gradient-to-r from-mstheme via-mstheme to-transparent"></div>
        </div>
        {/* heading ends here */}
        {/* director container starts here */}
        <div className="w-full px-4 md:px-0 md:w-[1200px] mx-auto shadow-2xl flex my-12 rounded-lg flex-col md:flex-row justify-center items-center gap-16">
          <div className="flex flex-col md:flex-row items-center justify-center gap-12 p-12  bg-gray-100 rounded-lg shadow-lg">
            {/* Image section */}
            <div className="md:w-1/3">
              <img
                className="rounded-3xl shadow-lg object-cover"
                src="/assets/images/about/director.jpg"
                alt="Omara Khan Massoudi"
              />
            </div>

            {/* Text content section */}
            <div className="md:w-2/3 text-center md:text-left">
              <h1 className="text-mstheme text-4xl font-bold mb-2">
                {t("director-name")}
              </h1>
              <h2 className="text-mstheme text-xl mb-6">
                {t("director-name-sub-title")}
              </h2>

              <p className="text-gray-700 leading-relaxed">
                {t("director-msg-desc-1")}.
              </p>
              <p className="text-gray-700 leading-relaxed mt-4">
                {t("director-msg-desc-2")}
              </p>
              <div className="mt-4">


                <div className="flex justify-between bg-gray-100 text-mstheme p-2 mt-2">
                  <a
                    href="https://www.facebook.com/nationalmuseum.af"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[10px] sm:text-xl mx-1 sm:mx-3   rounded"
                  >
                    <TbBrandYoutube className="hover:scale-110  transition-transform duration-300 " />
                  </a>
                  <a
                    href="https://www.facebook.com/nationalmuseum.af"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[10px] sm:text-xl mx-1 sm:mx-3   rounded"
                  >
                    <FiFacebook to='https://www.facebook.com/profile.php?id=100038284699429' className="hover:scale-110  transition-transform duration-300" />
                  </a>
                  <a
                    href="https://twitter.com/nationalmuseum.afg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[10px] sm:text-xl mx-1 sm:mx-3   rounded"
                  >
                    <BsTwitterX className="hover:scale-110  transition-transform duration-300" />
                  </a>
                  <a
                    href="https://www.instagram.com/nationalmuseum.afg/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[10px] sm:text-xl mx-1 sm:mx-3   rounded"
                  >
                    <FiInstagram className="hover:scale-110  transition-transform duration-300" />
                  </a>
                  <a
                    href="https://linkedin.com/nationalmuseum.afg"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[10px] sm:text-xl mx-1 sm:mx-3   rounded"
                  >
                    <FiLinkedin to='https://www.linkedin.com/in/areyana-group-zabet-khan-60198b209?originalSubdomain=af' className="hover:scale-110  transition-transform duration-300" />
                  </a>
                </div>
              </div>
            </div>
            <div>
            </div>
          </div>
        </div>
          
        <div className="w-full md:w-[1450px] m-auto mb-16">
      <div className="py-10 ">
        <h1 className="pb-5 text-3xl md:text-4xl font-semibold text-center">
          Certificates
        </h1>
        <div className="bg-usetheme w-[10%] h-1 mt-1 mb-10 mx-auto"></div>

        {/* React Slick Slider */}
        <Slider {...sliderSettings}>
          {cardData.map((item, index) => (
            <div key={index} className="p-2">
              <div className="group shadow-2xl cursor-pointer w-[320px]  flex flex-col mx-auto mb-5">
                <div className="flex-grow overflow-hidden">
                  <img
                    src={item.image}
                    alt=""
                    className="w-full h-72 object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                  />
                </div>
                {/* <div className="px-5 py-3">
                  <h1 className="text-xl font-semibold hover:underline">
                    {item.title}
                  </h1>
                  <p className="pt-3">{item.desc}</p>
                  <p className="pt-3 pb-6">Budget: ${item.bughet}</p>
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="w-full h-1 mt-5 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
    </div>

        <div className="w-full px-4 md:px-0 md:w-[1200px] mx-auto my-10">
          <h1 className="text-4xl font-bold my-8 text-mstheme">
            {t("directorateContact")}
          </h1>

          <Link to='mailto:zabit.khan@areyanagroup.com' className="flex items-center hover:text-mstheme "><BiLogoGmail /><span className="pl-2">  zabit.khan@areyanagroup.com</span>  </Link>
          <Link to="tel:+93781111773" className="flex items-center hover:text-mstheme mt-3 mb-10" ><FaPhoneAlt /><span className="pl-2">  {t("visitorInfo-phone")}</span>  </Link>
          <div className="w-full h-1 bg-gradient-to-r from-mstheme via-mstheme to-transparent"></div>
        </div>
        {/* director container ends here */}
      </div>

    </div>
 </>
  );
};

export default AboutDirectorates;
