import React from "react";
import AboutHistory from "../../Components/about-us-page-components/AboutHistory";
import Pagination from "../../Components/Pagination";

const AboutUs = () => {
  return (
    <>
    <AboutHistory/>

    </>
  );
};

export default AboutUs;
