import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { FaMapMarkerAlt, FaPhoneAlt, FaArrowUp } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";
import mlogo from "../images/logo/mlogo.png";
import { BiLogoGmail } from "react-icons/bi";
import { SiScribd } from "react-icons/si";
import { ImWarning } from "react-icons/im";
import { BsTwitterX } from "react-icons/bs";
import { TbBrandYoutube } from "react-icons/tb";
const Footer = () => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);

    // Show the button when scrolling down
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    // Scroll to top function
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scroll effect
        });
    };

    return (
        <>
            <div className=" px-10 flex flex-col sm:flex-row justify-between items-center  mt-8 py-10 bg-red-100">
                <Link className="bg-mstheme text-white  px-4 py-2 rounded text-md font-medium">  {t("eventSection-btn")} {t("footer.heading")} </Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://web.archive.org/web/20160322002524/http://areyanagroup.com/introduction.php' className="w-20"><img src="/assets/images/Home/l3.jpg" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://cage.report/NCAGE/SHM85' className="w-12 "><img src="/assets/images/Home/l5.jpg" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://www.tam.usace.army.mil/Portals/77/docs/Contracting/Contract%20Awards/FY13%20and%20FY14%20Contract%20Awards.xlsx' className=" text-white rounded-full"><img src="https://cdn.pixabay.com/photo/2020/09/17/22/52/website-5580513_640.png" className="w-9" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://www.scribd.com/document/365108060/AGCC-Response-of-Performance-Completed-Projects-letter-of-recommendAGCC-SS-pdf' className=""><img src="https://gotrialpro.com/wp-content/uploads/edd/2024/07/Scribd-Free-Trial-1.png" className="w-24" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://www.asbca.mil/Portals/143/Decisions/2018/60649%20Areyana%20Group%20of%20Construction%20Company%2010.26.18.pdf?ver=iwTy_watLVjwM94Cxn-F1Q%3D%3D' className="w-24"><img src="assets/images/Home/l1.jpg" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://opengovus.com/sam-entity/850480848#google_vignette' className="w-36"><img src="/assets/images/Home/l2.jpg" alt="" /></Link>
                <Link target="_blank" rel="noopener noreferrer" to='https://www.governmentcontractswon.com/department/defense/areyana-group-of-construction-company-850480848.asp?yr=13' className="w-64 "><img src="/assets/images/Home/l4.jpg" alt="" /></Link>
            </div>
            <div className="px-10 pt-7 flex flex-col sm:flex-row justify-between gap-5 mt-10 pb-5 bg-[#FBE5D6] ">
                <div className="space-y-7 w-full md:w-2/4">
                    {/* <h1 className=" text-xl font-medium">{t("footer.heading")}</h1> */}
                    <div className="navbar-start flex items-center w-full">
                        <Link
                            to="/"
                            className="flex items-center text-lg font-bold  rounded"
                        >
                            <img
                                src={mlogo}
                                alt="National Museum Afghanistan Logo"
                                className="h-16 md:h-20 w-auto mr-2"
                            />
                            {/* <p className="text-[20.1px] text-center font-bold ">{t("header.main-heading")}</p> */}
                        </Link>
                    </div>
                    <p className="text-mstheme">{t("footer.about")}   <Link to='/about/history' className="bg-mstheme text-white rounded px-2 py-1 ">{t("eventSection-btn")}</Link></p>

                </div>
                <div className="w-full md:w-1/5">
                    <h1 className="bg-gray-500 text-white rounded px-4 text-xl font-medium w-32">{t("footer.home")}</h1>
                    <ul className="flex flex-col gap-2 text-center lg:text-start">
                        <li className="mt-5">
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/"
                            >
                                {t("footer.home1")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/about"
                            >
                                {t("footer.home2")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/products"
                            >
                                {t("footer.home3")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/gallery"
                            >
                                {t("footer.home4")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/contact"
                            >
                                {t("footer.home5")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/contact"
                            >
                                {t("footer.home6")}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="w-full md:w-1/5">
                    <h1 className="bg-gray-500 rounded text-white px-4 text-xl font-medium w-40">{t("footer.quick-links")}</h1>
                    <ul className="flex flex-col gap-2 text-center lg:text-start">
                        <li className="mt-5">
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/"
                            >
                                {t("footer.quick-links-0")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/about"
                            >
                                {t("footer.quick-links-1")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/products"
                            >
                                {t("footer.quick-links-5")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/gallery"
                            >
                                {t("footer.quick-links-3")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/contact"
                            >
                                {t("footer.quick-links-4")}
                            </Link>
                        </li>
                        <li>
                            <Link
                                className="text-lg font-medium hover:bg-mstheme_hover hover:text-white px-2  rounded"
                                to="/contact"
                            >
                                {t("footer.quick-links-2")}
                            </Link>
                        </li>

                    </ul>
                </div>
                <div>
                    <h1 className="bg-gray-500 text-white px-4 rounded text-xl font-medium w-40">    {t("header.seventh-menu")}</h1>
                    <div className="flex flex-col items-center gap-1 lg:items-start mt-6 lg:mt-0">
                        <p className="flex items-start justify-center gap-2 mt-5">
                            <FaMapMarkerAlt className="text-mstheme mt-2" />
                            <span>{t("visitorInfo-location")}</span>
                        </p>
                        <p className="flex items-center justify-center gap-2">
                            <FaPhoneAlt className="text-mstheme" />
                            <span className="ltr:text-left">{t("visitorInfo-phone")}</span>
                        </p>
                        {/* <p className="flex items-center justify-center gap-2">
                            <MdEmail className="text-mstheme" />
                            <span>{t("visitorInfo-email")}</span>                         
                        </p> */}
                        <p className="flex items-center justify-center gap-2">
                            <BiLogoGmail className="text-mstheme" />
                            <span>info@areyanagroup.com</span>
                        </p>
                        <p className="flex items-center justify-center gap-2">
                            <BiLogoGmail className="text-mstheme" />
                            <span>agcc@areyanagroup.com</span>
                        </p>
                    </div>
                </div>
            </div>

            <footer className="bg-[#FBE5D6]   ">


                {/* Social Links */}


                {/* Footer Bottom Section */}
                <div className="flex flex-col sm:flex-row justify-start items-center py-8">
                    <div className="mx-10">
                        <div className=" text-[8px] sm:text-xl bg-gray-500 w-36 mx-12 text-white rounded text-center  sm:font-semibold sm:px-4 "
                            dangerouslySetInnerHTML={{ __html: t("top-header.follow") }}
                        />

                        <div className="flex  p-2 mt-2">
                            <a
                                href="https://www.facebook.com/nationalmuseum.af"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[13px] sm:text-2xl mx-1 sm:mx-3 text-mstheme font-bold  rounded"
                            >
                                <TbBrandYoutube className="hover:scale-110  transition-transform duration-300 " />
                            </a>
                            <a
                                href="https://www.facebook.com/nationalmuseum.af"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[13px] sm:text-2xl mx-1 sm:mx-3 text-mstheme font-bold  rounded"
                            >
                                <FiFacebook to='https://www.facebook.com/profile.php?id=100038284699429' className="hover:scale-110  transition-transform duration-300" />
                            </a>
                            <a
                                href="https://twitter.com/nationalmuseum.afg"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[13px] sm:text-2xl mx-1 sm:mx-3 text-mstheme font-bold  rounded"
                            >
                                <BsTwitterX className="hover:scale-110  transition-transform duration-300" />
                            </a>
                            <a
                                href="https://www.instagram.com/nationalmuseum.afg/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[13px] sm:text-2xl mx-1 sm:mx-3 text-mstheme font-bold  rounded"
                            >
                                <FiInstagram className="hover:scale-110  transition-transform duration-300" />
                            </a>
                            <a
                                href="https://linkedin.com/nationalmuseum.afg"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-[13px] sm:text-2xl mx-1 sm:mx-3 text-mstheme font-bold  rounded"
                            >
                                <FiLinkedin to='https://www.linkedin.com/in/areyana-group-zabet-khan-60198b209?originalSubdomain=af' className="hover:scale-110  transition-transform duration-300" />
                            </a>
                        </div>
                    </div>

                </div>
                <div className="flex justify-center items-center mt-10 mb-1">
                    <ImWarning className="text-3xl text-mstheme" size={80}/>

                    <div className="w-full sm:w-[60%] p-3  bg-gray-500 text-white rounded-3xl text-center flex">
                        <div dangerouslySetInnerHTML={{ __html: t("disclaimer") }}
                            
                        />
                    </div>
                </div>


                <div className="bg-mstheme text-white text-center  flex flex-col md:flex-row justify-center lg:space-x-28 py-5">
                    <p className="text-sm md:text-lg"> {t("footer.desc")}</p>

                    <a href="https://ariadelta.af/" target="_blank" rel="noopener noreferrer" className="text-lg">
                        <span className="text-yellow-400  font-medium">{t("footer.company-developer")}</span>: {t("footer.company-name")} </a>
                </div>

                {/* Scroll to Top Button */}
                {isVisible && (
                    <button
                        onClick={scrollToTop}
                        className="fixed bottom-8 right-6 bg-mstheme hover:bg-blue-800 text-white p-3 rounded-full shadow-lg transition-all duration-300 z-50"
                        title={t("Back to Top")}
                    >
                        <FaArrowUp className="text-md md:text-2xl" />
                    </button>
                )}
            </footer>
        </>
    );
};

export default Footer;
