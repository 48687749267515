import React from 'react';

const machinesData = [
  {
    title: "Water Treatment Equipment",
    description: "High-quality water treatment equipment for industrial and residential use.",
    image: "https://cdn.pixabay.com/photo/2016/11/21/16/37/loader-1846346_640.jpg", // Replace with actual image URLs
  },
  {
    title: "Sewage Treatment Equipment",
    description: "Advanced sewage treatment solutions tailored to meet your needs.",
    image: "https://cdn.pixabay.com/photo/2021/11/01/12/35/carabiner-6760243_640.jpg",
  },
  {
    title: "Pumping Equipment",
    description: "Durable and efficient pumps for various applications.",
    image: "https://cdn.pixabay.com/photo/2020/07/26/08/31/oil-5438804_640.jpg",
  },
  {
    title: "Propane & Natural Gas Equipment",
    description: "Safe and reliable propane and natural gas equipment.",
    image: "https://cdn.pixabay.com/photo/2016/10/18/15/13/gas-bottle-1750491_640.jpg",
  },
  {
    title: "Fuel Distribution Facilities",
    description: "Comprehensive solutions for fuel storage and distribution.",
    image: "https://cdn.pixabay.com/photo/2016/10/18/15/13/gas-bottle-1750491_640.jpg",
  },
  {
    title: "Auxiliary Power Installation",
    description: "Uninterrupted power solutions with auxiliary power installations.",
    image: "https://cdn.pixabay.com/photo/2016/10/18/15/13/gas-bottle-1750491_640.jpg",
  },
  {
    title: "Electric Motors",
    description: "High-performance electric motors for various industries.",
    image: "https://cdn.pixabay.com/photo/2019/08/03/13/10/electric-car-4381728_640.jpg",
  },
  {
    title: "Meter Pits",
    description: "Reliable and durable meter pits for water and gas systems.",
    image: "https://cdn.pixabay.com/photo/2015/12/07/10/55/electric-1080584_640.jpg",
  },
];

const Machines = () => {
  return (
    <div className="bg-gray-50 py-12">
      <div className="container mx-auto px-6">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-10">
          Machines and Equipment
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {machinesData.map((machine, index) => (
            <div key={index} className="bg-white shadow-md rounded-lg overflow-hidden">
              <img
                src={machine.image}
                alt={machine.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-2">
                  {machine.title}
                </h2>
                <p className="text-gray-600">{machine.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Machines;
