import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Certificates = () => {
  const { t } = useTranslation();
  const cardData = [
    // {
    //   image:
    //     "assets/images/contact/c1.png",
    //   title: t("ongoingProjects-1st-project-title"),
    //   desc: t("ongoingProjects-1st-project-desc"),
    //   bughet:t("ongoingProjects-1st-project-budget")
    // },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },
    {
      image:
        "assets/images/contact/c2.png",
      title: t("ongoingProjects-2nd-project-title"),
      desc: t("ongoingProjects-2nd-project-desc"),
      bughet:t("ongoingProjects-2nd-project-budget")
    },
    {
      image:
        "assets/images/contact/c3.png",
      title: t("ongoingProjects-3rd-project-title"),
      desc: t("ongoingProjects-3rd-project-desc"),
      bughet:t("ongoingProjects-3rd-project-budget")
    },

 
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="w-full md:w-[1450px] m-auto mb-16">
      <div className="py-10 ">
        <h1 className="pb-5 text-3xl md:text-4xl font-semibold text-center">
          Certificates & Memorandum For Record
        </h1>
        <div className="bg-usetheme w-[10%] h-1 mt-1 mb-10 mx-auto"></div>

        {/* React Slick Slider */}
        <Slider {...sliderSettings}>
          {cardData.map((item, index) => (
            <div key={index} className="p-2">
              <div className="group shadow-2xl cursor-pointer w-[320px] h-[350px] flex flex-col mx-auto mb-5">
                <div className="flex-grow overflow-hidden">
                  <img
                    src={item.image}
                    alt=""
                    className="w-full h-72 object-cover transform transition-transform duration-500 ease-in-out group-hover:scale-110"
                  />
                </div>
                <div className="px-5 py-3">
                  <h1 className="text-xl font-semibold hover:underline">
                    {item.title}
                  </h1>
                  <p className="pt-3">{item.desc}</p>
                  <p className="pt-3 pb-6">Budget: ${item.bughet}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="w-full h-1 mt-5 bg-gradient-to-r from-transparent via-mstheme to-transparent"></div>
    </div>
  );
};

export default Certificates;
