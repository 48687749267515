import React from 'react';
import { useTranslation } from 'react-i18next';

const OurPriorities = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-full mx-14   ">
      <h2 className=" text-3xl md:text-4xl font-semibold text-center">{t('ourPriorities-title')}</h2>
      <div className="bg-usetheme w-[10%] h-1  mt-1 mb-10 mx-auto"></div>

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-10">
        <div className="bg-white p-6 border-l-2 border-mstheme hover:shadow-md cursor-pointer">
          <h3 className="text-xl font-semibold mb-4 text-usetheme">{t('ourPriorities-clients-title')}</h3>
          <p className="text-gray-700">{t('ourPriorities-clients-desc')}</p>
        </div>

        <div className="bg-white p-6 border-l-2 border-mstheme hover:shadow-md cursor-pointer">
          <h3 className="text-xl font-semibold mb-4 text-usetheme ">{t('ourPriorities-people-title')}</h3>
          <p className="text-gray-700">{t('ourPriorities-people-desc')}</p>
        </div>

        <div className="bg-white p-6 border-l-2 border-mstheme hover:shadow-md cursor-pointer">
          <h3 className="text-xl font-semibold mb-4 text-usetheme ">{t('ourPriorities-financial-title')}</h3>
          <p className="text-gray-700">{t('ourPriorities-financial-desc')}</p>
        </div>

        <div className="bg-white p-6 border-l-2 border-mstheme hover:shadow-md cursor-pointer">
          <h3 className="text-xl font-semibold mb-4 text-usetheme ">{t('ourPriorities-management-title')}</h3>
          <p className="text-gray-700">{t('ourPriorities-management-desc')}</p>
        </div>

        <div className="bg-white p-6 border-l-2 border-mstheme hover:shadow-md cursor-pointer">
          <h3 className="text-xl font-semibold mb-4 text-usetheme ">{t('ourPriorities-business-title')}</h3>
          <p className="text-gray-700">{t('ourPriorities-business-desc')}</p>
        </div>
      </div>
      <div className="w-full mt-20 h-1 bg-gradient-to-r from-transparent via-mstheme to-transparent "></div>

    </div>
  );
};

export default OurPriorities;
